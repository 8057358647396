.check-path-animation {
  path {
    animation: check-path-animation ease-out;

    stroke-linecap: round;
  }
}

@keyframes check-path-animation {
  from {
    stroke-dasharray: 0 50;
  }
  to {
    stroke-dasharray: 50;
  }
}

.cross-path-animation {
  .path {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    stroke-linecap: round;

    transition: stroke-dashoffset ease-out;
  }
}
