@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.truncate {
  @include truncate;
}

$prefixes: "";
