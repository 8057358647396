@import "@kikoff/client-utils/src/stylesheets/constants/stacking-context.scss";

.status-alerts {
  position: fixed;
  z-index: z(error);
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
