@use './index.scss' as light-colors;
@use './dark.scss' as dark-colors;

$prefixes: "color: bg: color-base:";

.color\: {
  &inherit {
    color: inherit;
  }

  @each $key, $value in module-variables(light-colors) {
    &#{$key} {
      color: var(--#{$key}) !important;
    }
  }
}

.bg\: {
  @each $key, $value in module-variables(light-colors) {
    &#{$key} {
      background: var(--#{$key}) !important;
    }
  }
}

.color-base\:dugout {
  --strong: var(--strong-on-dugout);
  --moderate: var(--moderate-on-dugout);
  --moderate-weak: var(--moderate-on-dugout);
  --weak: var(--weak-on-dugout);
  --container: var(--dugout);
}

.color-base\:primary-strong {
  --strong: var(--on-primary-strong);
  --moderate: var(--on-primary-strong);
  --moderate-weak: var(--on-primary-strong);
  --weak: var(--on-primary-strong);
  --container: var(--primary-strong);
}

.color-base\:inverse {
  --strong: var(--strong-on-inverse);
  --moderate: var(--moderate-on-inverse);
  --moderate-weak: var(--moderate-on-inverse);
  --weak: var(--weak-on-inverse);
  --container: var(--inverse);
}

@function rgb-vals($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root,
html[data-theme="light"] {
  transition: 0.5s;
  @each $key, $value in module-variables(light-colors) {
    --#{$key}: #{$value};
    --#{$key}-rgb: #{rgb-vals($value)};
  }
  --gradient: radial-gradient(
      71.16% 51.32% at 90% 60.34%,
      rgba(162, 221, 255, 0.4) 0%,
      rgba(162, 221, 255, 0) 100%
    ),
    radial-gradient(
      45.76% 51.46% at 24.27% 50%,
      rgba(237, 255, 117, 0.52) 0%,
      rgba(237, 255, 117, 0) 100%
    ),
    radial-gradient(
      116.06% 116.06% at 49.87% 1.81%,
      #71d878 0%,
      rgba(113, 216, 120, 0) 100%
    );
  --gradient-button: linear-gradient(
      237deg,
      rgba(162, 221, 255, 0.4) -86.46%,
      rgba(162, 221, 255, 0) 255.1%
    ),
    linear-gradient(
      263deg,
      rgba(237, 255, 117, 0.8) -41.12%,
      rgba(237, 255, 117, 0) 56.95%
    ),
    linear-gradient(88deg, #71d878 -20.06%, rgba(113, 216, 120, 0) 127.04%);
  --gradient-background-green: radial-gradient(
      35.53% 93.88% at 111.28% 70.8%,
      rgba(162, 221, 255, 0.28) 60%,
      rgba(162, 221, 255, 0) 100%
    ),
    radial-gradient(
      74.06% 133.77% at -34.21% 142.35%,
      rgba(237, 255, 117, 0.13) 60%,
      rgba(237, 255, 117, 0) 100%
    ),
    radial-gradient(
      194.02% 108.47% at 89.35% 10.66%,
      rgba(113, 216, 120, 0.8) 60%,
      rgba(217, 217, 217, 0) 100%
    );
  --gradient-background-orange: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.3) 100%
    ),
    radial-gradient(
      73% 81.79% at 100% 0.04%,
      #f99f1c 0%,
      rgba(183, 236, 72, 0.15) 100%
    ),
    radial-gradient(116.07% 141.42% at 0% 0.03%, #fece2f 0%, #ffd800 100%),
    radial-gradient(47.42% 48.44% at 100% 100.03%, #f99f1c 0%, #f99f1c 100%),
    radial-gradient(
      57.52% 69.95% at 1.09% 100.03%,
      #fece2f 0%,
      rgba(106, 196, 153, 0.2) 100%
    );

  // background linear gradients
  --gradient-green: linear-gradient(273deg, #81ff9f 0%, #86eac4 100%);
  --gradient-orange: linear-gradient(273deg, #e8e77e -2%, #ffa216 100%);
  --gradient-blue: linear-gradient(273deg, #89cfff 0%, #3f9aff 100%);
  --gradient-red: linear-gradient(
    273deg,
    var(--red-50) -78.27%,
    var(--red-600) 100%
  );

  // Live Utilization
  --gradient-optimal: radial-gradient(
      49.06% 79.48% at 64.8% 67.98%,
      rgba(40, 158, 231, 0.8) 12.5%,
      rgba(36, 163, 242, 0) 100%
    ),
    radial-gradient(
      84.84% 84.84% at 50.13% 19.21%,
      #5dcfb5 0%,
      rgba(93, 207, 201) 100%
    );
  --gradient-high: radial-gradient(
      31.39% 63.16% at 89.87% 54.14%,
      rgba(243, 128, 163, 0.8) 0%,
      rgba(234, 151, 209, 0) 100%
    ),
    radial-gradient(108.64% 136.56% at 4.53% 2.35%, #fec055 0%, #ebb3f7 100%);
}

html[data-theme="dark"] {
  @each $key, $value in module-variables(dark-colors) {
    --#{$key}: #{$value};
    --#{$key}-rgb: #{rgb-vals($value)};
  }
}

@media (prefers-color-scheme: dark) {
  html[data-theme="system"] {
    @each $key, $value in module-variables(dark-colors) {
      --#{$key}: #{$value};
      --#{$key}-rgb: #{rgb-vals($value)};
    }
  }
}
