.reasons {
  ul {
    padding-left: 16px;
    margin: 0;
    list-style-type: "•";

    > li {
      padding-left: 8px;
      margin-bottom: 8px;
    }
  }
}
