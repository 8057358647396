.container {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: none;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background: none;

  color: inherit;
  font: inherit;
  text-align: inherit;
  text-decoration: none;
}
.underline-button {
  display: inline-block;
  width: fit-content;
  padding: 1px 3px;

  border-bottom: 1px solid black;

  font-size: 14px;
}

.status-button {
  position: relative;

  display: block;
  overflow: hidden;

  border: none;
  cursor: pointer;

  font: inherit;
  outline: none;
  .center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .status {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    width: 100%;
    height: 100%;

    font-weight: 500;

    pointer-events: none;

    &:global(.pending) {
      background-color: var(--container);
      color: var(--source-color);
      opacity: 0;
      > div {
        height: 100%;

        background: rgba(var(--source-color-rgb), 0.3);
      }
    }
    &:global(.success) {
      left: 50%;

      width: 0;

      background-color: var(--primary);

      color: var(--dugout);

      .text {
        overflow: hidden;
        width: 0;

        white-space: nowrap;
      }
    }
  }
  &:disabled {
    cursor: inherit;
  }
}

.styled-button:global {
  border: none;
  color: white;
  font: inherit;
  font-size: 14px;
  font-weight: 500;
  outline: none;

  transition-duration: 0.3s;
  transition-property: padding, border-radius, color, background-color;

  &.default-shape {
    border-radius: var(--card-border-radius);
  }
  &.rounded-square-shape {
    padding: 0.7em 2em;

    border-radius: var(--card-border-radius);
  }
  &.round-shape {
    padding: 1em 2em;

    border-radius: 5em;

    letter-spacing: 1.6px;
  }
  &.active-type {
    background: var(--source-color);
  }
  &.success-type {
    background: var(--success);
  }

  &.disabled-type {
    background: var(--inactive-weak);
    color: var(--on-inactive-weak);
    cursor: inherit;
  }

  &.pending-type {
    background: rgba(var(--source-color-rgb), 0.5);
    color: var(--dugout);
    cursor: inherit;
  }
  &.inverted-type {
    background: white;
    color: var(--source-color);
  }

  &.secondary-variant {
    border: 1px solid var(--source-color);
    background: none;
    color: var(--source-color);
  }
}
