.separator {
  display: flex;
  align-items: center;
  text-align: center;

  --line-color: var(--weak);
}

.separator::before,
.separator::after {
  height: 1px;
  flex: 1;
  background: var(--line-color);
  content: "";
}

.separator > div {
  padding: 0 0.25em;
}
