.with-trailing {
  display: flex;

  &.center {
    align-items: center;
  }

  > :first-child {
    width: 100%;
    flex: 1;
  }
}
