.unstyled,
.container,
.primary,
.primary-underlined {
  color: inherit;
  font: inherit;
  text-decoration: inherit;
}

.underlined {
  color: inherit;
  font: inherit;
}

.container {
  display: block;
}

.primary,
.primary-underlined {
  color: var(--primary);
  font-weight: 500;
}

.primary-underlined {
  text-decoration: underline;
}

.new-line {
  display: block;
}

.fit {
  width: fit-content;
}
