.k-button {
  box-sizing: border-box;
  padding: var(--local-base-padding)
    calc(var(--local-base-padding) * var(--local-padding-multiplier));
  border: none;
  background: none;
  border-radius: 48px;

  font: inherit;
  font-weight: 500 !important;
  letter-spacing: 0.01em !important;
  line-height: 24px !important;
  transition: opacity 0.3s, background-color 0.3s, box-shadow 0.3s, color 0.3s;
  --local-padding-multiplier: 2;

  // Must appear before variant_*
  &.require-background {
    background: var(--container);
  }

  &.variant_ {
    &accent {
      background: var(--accent);
    }
    &primary {
      background: var(--primary);
      color: var(--on-primary);
    }

    &black {
      background: var(--inverse);
      color: var(--strong-on-inverse);
    }

    &white {
      background: var(--white);
      color: var(--strong);
    }

    &container {
      background: var(--container);
      color: var(--strong);
    }

    &text-only {
      color: var(--strong);
    }

    &dugout {
      background: var(--dugout);
      color: var(--strong-on-dugout);
    }

    &card-continue {
      background: var(--primary-dugout);
      color: var(--strong);
    }

    &primary-dugout {
      background: var(--primary-dugout);
      color: var(--primary-on-dugout);
    }

    &primary-outline {
      background: var(--container);
      color: var(--strong);
      outline: 2px solid var(--primary);
    }

    &black-outline {
      background: var(--container);
      box-shadow: 0 0 0 2px var(--strong);
      color: var(--strong);
    }

    &grey-outline {
      background: var(--container);

      box-shadow: 0 0 0 1px var(--weak); // outline should be 1px to support bolded outlines when highlighting button choice
      color: var(--strong);
    }

    &text-primary {
      color: var(--primary);
    }

    &text-underline {
      color: var(--strong);
      font-weight: 600 !important;
      text-decoration: underline;
    }

    &text-discouraged {
      color: var(--error);
    }

    &green-gradient {
      background: var(--gradient-green);
      color: var(--strong);
    }

    &orange-gradient {
      background: var(--gradient-orange);
      color: var(--strong);
    }

    &circle {
      aspect-ratio: 1;
      border-radius: 50%;
    }
  }

  &.size_ {
    &hug {
      padding: 0;
      border-radius: 0;
      text-align: left;
    }

    &small {
      --local-base-padding: 6px;
    }

    &standard,
    &full-width {
      --local-base-padding: 12px;
    }

    &full-width {
      width: 100%;
    }
  }
  &.wide {
    --local-padding-multiplier: 4;
  }

  &.state_ {
    &active {
      cursor: pointer;
      opacity: 1;
    }

    &disabled {
      cursor: default;
      opacity: 0.3;
    }
  }
}
