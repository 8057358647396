.new-content {
  ul {
    padding-left: 16px;

    > li {
      padding-left: 8px;
    }
  }

  ul.outer-list {
    list-style-type: "";

    > li {
      &::marker {
        color: var(--primary);
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    ul.inner-list {
      list-style-type: "•";
    }
  }
}
