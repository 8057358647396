.freeform {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 6px;
  padding-left: 16px;
  background-color: var(--container);

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 2px); // cover the gap bw textarea and bottom
    background: var(--container);
    content: "";
  }

  &::after {
    height: 100%;
    border-radius: 24px;
    box-shadow: 0 0 0 1px var(--outline);
  }

  > textarea {
    z-index: 2;
    min-width: 0;
    flex: 1;
    padding: 0;
    border: none;
    background: none;
    caret-color: var(--primary);
    font-family: inherit;
    font-size: 16px;
    outline: none;
    resize: none;
  }

  > button {
    z-index: 2;
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: var(--primary);
    border-radius: 50%;
    color: var(--strong-on-inverse);
    font: inherit;

    &:disabled {
      background: var(--weak);
    }
  }
}
