.site-tour {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  .highlight {
    position: absolute;

    pointer-events: none;
  }
  .guide {
    position: absolute;

    max-width: calc(100% - 20px);
    padding: 0;
    animation: fade-in 0.5s;

    transition: transform 0.5s, opacity 0.5s;
    .guide-content {
      padding: 20px;
      & > header.title {
        font-weight: 500;
      }
      & > .description {
        color: var(--strong);
      }
    }
    .actions {
      display: flex;

      overflow: hidden;

      border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
      .action {
        flex: 1;

        cursor: pointer;
        .next,
        .close {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;

          background: var(--new-primary-color);

          color: var(--dugout);
          font-weight: 500;
        }
      }
    }
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
