@use "sass:string";
@use "sass:list";

$properties: padding, margin;
$directions: top, right, bottom, left;
$aliases: (
  x: (
    right,
    left,
  ),
  y: (
    top,
    bottom,
  ),
);
// 8px/0.5rem multipliers
$sizes: 0, 0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10;

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

@function negate($value) {
  @if $value == 0 {
    @return 0;
  }
  @return -$value;
}

@function char0($str) {
  @return string.slice($str, 0, 1);
}

@function replace($string, $search, $replace: "") {
  $index: string.index($string, $search);

  @if $index {
    @return string.slice($string, 1, $index - 1) + $replace +
      string.slice($string, $index + string.length($search));
    @return string.slice($string, 1, $index - 1) + $replace +
      string.replace(
        string.slice($string, $index + string.length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

$prefixes: "";

#app-root {
  @each $property in $properties {
    // All directions, lowest priority
    @each $size in $sizes {
      $prefix: char0($property) + "-";
      $prefixes: $prefixes + " " + $prefix;
      $class: $prefix + $size;
      .#{replace($class, ".", "\\.")} {
        #{$property}: $size * 0.5rem;
      }
      @if $property == margin {
        $n_class: "-" + $prefix + $size;
        .#{replace($n_class, ".", "\\.")} {
          #{$property}: negate($size * 0.5rem);
        }
      }
    }

    // Vertical and horizontal, overriden by specific direction
    @each $alias, $directions in $aliases {
      @each $size in $sizes {
        $prefix: char0($property) + char0($alias) + "-";
        $prefixes: $prefixes + " " + $prefix;
        $class: $prefix + $size;
        .#{replace($class, ".", "\\.")} {
          @each $direction in $directions {
            #{$property}-#{$direction}: $size * 0.5rem;
          }
        }
        @if $property == margin {
          $n_class: "-" + $prefix + $size;
          .#{replace($n_class, ".", "\\.")} {
            @each $direction in $directions {
              #{$property}-#{$direction}: negate($size * 0.5rem);
            }
          }
        }
      }
    }

    // Specific directions, top priority
    @each $direction in $directions {
      @each $size in $sizes {
        $prefix: char0($property) + char0($direction) + "-";
        $prefixes: $prefixes + " " + $prefix;
        $class: $prefix + $size;
        .#{replace($class, ".", "\\.")} {
          #{$property}-#{$direction}: $size * 0.5rem;
        }
        @if $property == margin {
          $n_class: "-" + $prefix + $size;
          .#{replace($n_class, ".", "\\.")} {
            #{$property}-#{$direction}: negate($size * 0.5rem);
          }
        }
      }
    }
  }
}

@each $size in $sizes {
  $gap-class: "gap-#{$size}";
  .#{replace($gap-class, ".", "\\.")} {
    gap: #{$size * 0.5rem};
  }
}
