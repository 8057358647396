.text-input {
  position: relative;

  margin: 8px;

  color: var(--moderate-weak);

  .inner {
    display: flex;
    align-items: stretch;
    border: 1px solid var(--outline);

    background: var(--container);
    border-radius: 4px;

    transition: color 0.3s, border-color 0.3s;

    .actions {
      display: flex;
      padding-right: 8px;
      padding-left: 4px;
    }
  }
  .input-container {
    position: relative;
    flex: 1;
  }
  .icon {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }

  .input {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0 10px 16px;
    border: none;
    background: transparent;
    caret-color: var(--primary);
    color: var(--strong);
    font: inherit;
    font-size: 16px;
    line-height: 1;
    outline: none;

    &.no-label {
      padding: 16px;
    }

    &.centered-input {
      text-align: center;
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: 16px;
    display: flex;
    // Height of single line input, should label line 1 in textarea
    height: 50px;
    box-sizing: border-box;
    align-items: center;

    line-height: 1;

    pointer-events: none;

    transition-duration: 0.3s;
  }

  .error {
    padding: 4px 8px 0;

    color: var(--error);
    font-size: 12px;
  }

  &.focused {
    color: var(--strong);
    .inner {
      border-color: var(--primary);
    }
  }
  &.open .label {
    // Input font size + bottom padding
    padding-bottom: 16px + 12px;
    font-size: 12px;
  }
  &.invalid {
    .inner {
      border-color: var(--error);
    }
    .label {
      color: var(--error);
    }
    .input {
      caret-color: var(--error);
    }
  }
  &.has-icon {
    .input {
      padding-left: 12px;
    }
    .label {
      left: 12px;
    }
  }
}

.text-input-action {
  display: inline-flex;
  width: initial;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.visibility {
  font-size: 20px;
}

.text-only {
  width: 100%;
  min-width: none;
  box-sizing: border-box;
  border: none;
  background: none;
  outline: none;
}
