.autocomplete {
  position: relative;
  .input {
    width: calc(100% - var(--margin, 10px) * 2);
  }
  .predictions {
    position: absolute;
    // Above .info-tooltip
    z-index: 2;
    width: calc(100% - var(--margin, 10px) * 2);

    box-sizing: border-box;
    padding: 0;
    margin: 0 var(--margin, 10px);

    background: white;
    border-radius: 5px;
    // TODO(tom): update with palette
    box-shadow: 0 5px 5px 0 #ddd;

    li {
      padding: 0 20px;
      cursor: pointer;

      font-size: 15px;

      list-style-type: none;

      transition: background-color 0.3s;

      .wrapper {
        display: flex;
        overflow: hidden;
        padding: 10px 0;

        border-bottom: 1px solid #eee;

        text-overflow: ellipsis;
      }

      .marker {
        margin-right: 10px;

        color: var(--primary);
        font-size: 20px;

        transition: transform 0.3s;
        vertical-align: middle;
      }
      &.highlight {
        background: #f7f7f7;
        .marker {
          transform: translateY(-3px);
        }
      }
    }
  }
}

.autocomplete-preview {
  .input {
    width: calc(100% - (var(--margin) * 2));
  }

  .map {
    height: 0;

    border-radius: 5px;

    transition: height 0.3s;

    &.expand {
      height: 175px;
      margin: var(--margin);
    }
  }
}
