.generic-spinner {
  z-index: 2;

  width: fit-content;

  svg {
    width: 1em;
    height: 1em;

    animation: rotate 2s linear infinite;
    circle {
      animation: dash 1.5s ease-in-out infinite;

      stroke: var(--primary);
      stroke-linecap: round;
    }
  }

  &.center {
    display: flex;
    width: unset;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 0.01em, 3em;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 1.8em, 3em;
    stroke-dashoffset: -0.7em;
  }
  100% {
    stroke-dasharray: 1.8em, 3em;
    stroke-dashoffset: -2.49em;
  }
}

.message-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  header {
    flex-shrink: none;
    padding: 30px 10px;

    color: var(--strong);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    span {
      display: block;
      padding: 10px;
      font-size: 17px;

      font-weight: 500;
    }
  }
}
.ruler-loader {
  width: 100%;
  height: 100%;
}
