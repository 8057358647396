@import "@kikoff/client-utils/src/stylesheets/constants/stacking-context.scss";

.general-error {
  position: fixed;
  z-index: z(error);
  bottom: 10vh;

  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  padding: 0 10px;
}

.impersonating-banner {
  a,
  a:visited {
    position: fixed;
    z-index: z(debug);
    top: 0;
    left: 0;

    width: 100%;
    padding: 5px;

    animation: blinkingText 1.2s infinite;

    background: var(--blue-color);

    color: white;
    text-align: center;
  }
  button {
    position: fixed;
    z-index: z(debug);
    top: 3px;
    right: 50px;
    padding-top: 2px;
    padding-bottom: 3px;
    background-color: transparent;
    outline: white 1px solid;
  }
}

@keyframes blinkingText {
  0% {
    color: white;
  }
  80% {
    color: white;
  }
  99% {
    color: transparent;
  }
  100% {
    color: white;
  }
}
