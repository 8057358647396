div.chip {
  display: inline-flex;
  width: fit-content;
  min-width: max-content;
  height: 22px;
  align-items: center;

  padding: 0 12px;

  border-radius: 100px;
}

div.chip2 {
  position: relative;
  z-index: 0;
  overflow: hidden;
  width: fit-content;
  min-width: max-content;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.04em;
  transition: color 0.3s;

  .icon {
    transform: translateX(-6px);
  }

  &.compact {
    height: 1.75em;
    padding: 0 0.75em;
  }

  &.variant_ {
    &round {
      height: 2em;
      padding: 0 1em;
      border-radius: 2em;
    }
    &rounded-square {
      padding: calc(1em * 0.2) calc(1em * 0.5);
      border-radius: calc(1em * 0.3);
    }
  }

  &.uniform {
    width: 2em;
    justify-content: center;
    padding: 0;
  }

  &:not(.dugout-color) {
    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: currentColor;
      content: "";
      filter: brightness(200%) saturate(200%);
      opacity: 0.3;
    }
  }
}

html[data-theme="dark"] {
  div.chip2::before {
    filter: brightness(50%);
  }
}

@media (prefers-color-scheme: dark) {
  html[data-theme="system"] {
    div.chip2::before {
      filter: brightness(50%);
    }
  }
}
