.upload-attachments {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.attachments {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;

  .attachment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .delete-attachment {
    width: 16px;
    height: 16px;
    padding-top: 1px;
    margin-top: 2px;
    border-radius: 8px;
    line-height: 16px !important;
    text-align: center;
  }
}
