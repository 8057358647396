.generic-spinner {
  width: 1em;
  height: 1em;

  svg {
    animation: rotate 2s linear infinite;
    circle {
      animation: dash 1.5s ease-in-out infinite;

      stroke: var(--primary);
      stroke-linecap: round;
      @keyframes dash {
        0% {
          stroke-dasharray: 0.01em, 3em;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 1.8em, 3em;
          stroke-dashoffset: -0.7em;
        }
        100% {
          stroke-dasharray: 1.8em, 3em;
          stroke-dashoffset: -2.49em;
        }
      }
    }
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &.center {
    display: flex;
    width: unset;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
