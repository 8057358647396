.group.text-input {
  position: relative;

  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  margin: 10px;

  border-radius: var(--card-border-radius);

  transition-duration: 0.3s;
  > :first-child {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex: 1;
    padding: 20px 0 6px 10px;
    border: none;

    background: transparent;

    content: "";

    font-size: 15px;
    line-height: 1;
    outline: none;

    transition-duration: 0.3s;
  }
  textarea {
    resize: none;
  }
  label {
    position: absolute;
    top: 13px;
    left: 10px;

    color: grey;
    font-size: 16px;

    pointer-events: none;

    transition-duration: 0.3s;
  }
  &.open label {
    top: 5px;

    font-size: 12px;
  }

  .actions {
    flex-shrink: 0;
    padding: 0 2px;

    color: grey;
    font-size: 16px;
    .action {
      display: inline-flex;
      height: calc(100% - 10px);
      align-items: center;
      margin: 5px 3px;
      :global(.button) {
        display: flex;
        width: fit-content;
        width: -moz-fit-content;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border: none;

        background-color: var(--primary);
        border-radius: var(--card-border-radius);

        color: var(--dugout);
        cursor: pointer;
        font-family: inherit;
        font-size: inherit;
        outline: none;
      }
    }
  }
  &.default {
    background: var(--input-background);
    &.focused {
      background: white;
      // TODO(tom): update with palette
      box-shadow: 0 0 10px 0 #ddd;
    }
    &:not(.valid) {
      background: #ffe3e3;
      &.focused {
        background: #fee;
      }
    }
  }
  &.outlined {
    border: 1px solid #aaa;
    background: white;
    border-radius: 4px;

    transition: border-color 0.3s;

    &:not(.valid) {
      border-color: red;
      label {
        color: red;
      }
    }
  }
}

.select-menu {
  display: inline-block;
  select {
    appearance: none;
  }
  &.default {
    position: relative;

    margin: 10px;
    select {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 10px 6px;
      border: none;

      background: var(--dugout);
      border-radius: 5px;

      font-size: 15px;
      outline: none;

      transition-duration: 0.3s;

      &:focus {
        background: white;
        box-shadow: 0 0 10px 0 #ddd;
      }
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      width: 20px;
      height: 100%;
      align-items: center;
      justify-content: center;

      content: "▾";
      pointer-events: none;
    }
    label {
      position: absolute;
      top: 13px;
      left: 10px;

      color: grey;
      font-size: 16px;

      pointer-events: none;

      transition-duration: 0.3s;
    }
    &.open label {
      top: 5px;

      font-size: 12px;
    }
  }
  &.underline {
    position: relative;
    select {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 0;
      border: none;
      border-bottom: 2px solid grey;

      background: none;

      font-size: 15px;
      outline: none;

      transition-duration: 0.3s;

      &:focus {
        border-color: var(--primary);
      }
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      width: 20px;
      height: 100%;
      align-items: center;
      justify-content: center;

      content: "▾";
      pointer-events: none;
    }
  }
}

.swipe-action {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--primary);

  color: var(--dugout);
  font-weight: 500;

  .slider {
    position: absolute;
    left: 0;

    background: white;
    border-radius: 50%;
    box-shadow: 0 0 10px -4px black;
  }
}

.horizontal-radio-button {
  display: flex;
  flex-direction: row;
  margin: 10px;

  > div {
    display: inherit;
    flex: 1 1;
  }

  [type="radio"] {
    display: none;
  }

  .radio-button + label {
    position: relative;

    display: inline-block;
    overflow: hidden;
    width: 100%;
    padding: 0.7em 2em;

    border: 1px solid var(--primary);
    margin: 0 5px;
    border-radius: 10px;

    color: var(--primary);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    transition: all 300ms linear;
  }
  .radio-button:not(:checked) + label {
    background-color: var(--dugout);
  }
  .radio-button:checked + label {
    background-color: var(--primary);
    color: white;
  }
  .radio-button + label::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: linear-gradient(298deg, var(--red), var(--yellow-600));
    border-radius: 4px;

    content: "";
  }
  .radio-button + label .uil {
    display: block;
    padding-bottom: 10px;

    font-size: 24px;
    line-height: 24px;
  }
}
