.list-tile {
  display: flex;
  flex-direction: row;

  .leading {
    margin-right: 12px;
  }
  .inner {
    display: flex;
    min-width: 0;
    flex: 1;
    flex-direction: column;
    .title {
      padding-right: 4px;
    }
    .subtitle {
      margin-top: 2px;
    }
  }
  &.text-overflow {
    overflow: hidden;
    text-overflow: var(--text-overflow);
    white-space: nowrap;
  }
  &:not(.no-padding) {
    padding: 16px 0;
  }
}
