@use "./sizes.scss";

@font-face {
  font-family: "KikoffIconsV2";
  src: url("/fonts/KikoffIconsV2.woff2");
}

#app-root {
  .icon\: {
    @each $size, $value in module-variables(sizes) {
      &#{$size} {
        font-size: $value;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}
