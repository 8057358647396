.bottom-sheet {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  box-sizing: border-box;
  padding: 24px;
  background: var(--container);
  border-radius: 16px 16px 0 0;
  overflow-x: hidden;
  overflow-y: auto;

  .close {
    margin-bottom: 24px;
    font-size: 20px;
  }

  @media screen and (min-width: 700px) {
    border-radius: 16px;
  }
}
