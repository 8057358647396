@use "./typography.scss";
@import "./legacy.scss";
@import "./icons/styles.scss";

/* latin */
@font-face {
  font-display: swap;
  font-family: "Raleway";
  font-style: italic;
  font-weight: 100 900;
  src: url("https://assets.kikoff.com/public/fonts/Raleway-Italic-Variable.woff2")
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-display: swap;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 100 900;
  src: url("https://assets.kikoff.com/public/fonts/Raleway-Variable.woff2")
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "KikoffIcons";
  src: url("https://assets.kikoff.com/public/fonts/KikoffIcons.woff2");
}

html,
body {
  color: var(--strong);

  font-family: "Raleway", sans-serif, "KikoffIcons";
  font-feature-settings: "lnum" on, "calt" on, "liga" off, "ss02" on, "ss03" on,
    "ss05" on, "ss08" on, "ss09" on, "ss10" on, "kern" on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @each $property, $value in typography.$regular {
    #{$property}: #{$value};
  }
}

$prefixes: "text:";

.text\: {
  @each $key, $properties in module-variables(typography) {
    &#{$key} {
      @each $property, $value in $properties {
        #{$property}: #{$value} !important;
      }
    }
    &#{$key}\+ {
      @extend .text\:#{$key};
      font-weight: 600 !important;
    }
    &#{$key}\+\+ {
      @extend .text\:#{$key};
      font-weight: 700 !important;
    }
  }
  &\+ {
    font-weight: 600 !important;
  }
  &\+\+ {
    font-weight: 700 !important;
  }
}

h1 {
  @extend .text\:heading-1;
}
h2 {
  @extend .text\:heading-2;
}
h3 {
  @extend .text\:heading-3;
}
h4 {
  @extend .text\:heading-4;
}
h5 {
  @extend .text\:large\+;
}
h1,
h2,
h3,
h4,
h5 {
  margin-block: 1em;
}
