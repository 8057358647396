$banner-height: 5rem;

.logout-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.container {
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 1.5rem calc(#{$banner-height} + 1rem) 1.5rem;
  margin: 0 auto;
}

.image-wrapper {
  width: 160px;
  height: 160px;
  margin: 2rem 0;
}

.bottom-banner {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: $banner-height;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  background-color: var(--moderate-on-dugout);
}
