div.chat-overlay {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 0;

  > header {
    display: flex;
    padding: 16px 24px;
    border-bottom: 1px solid var(--outline);
    margin-bottom: -1px;
    .back {
      font-size: 20px;
    }
    .title {
      flex: 1;
      text-align: center;
    }

    > :first-child,
    > :last-child {
      width: 20px;
    }
  }

  .scroll-container {
    display: flex;
    min-height: 0;
    box-sizing: border-box;
    flex: 1;
    flex-direction: column-reverse;
    padding: 16px;
    padding-bottom: 1px;
    margin-bottom: 16px;
    overflow-y: auto;

    > div {
      position: relative;
      padding-top: 16px;
    }

    .fetch-more-messages {
      position: absolute;
      top: 0;
    }
  }
}

.chat {
  position: relative;
  overflow: hidden;

  .group {
    display: flex;
    align-items: flex-end;
    // 6px here instead of 8 since 2px is already included in each message
    padding: 6px 0;
    animation: grow 0.3s;

    @keyframes grow {
      from {
        padding: 0;
      }
    }

    .avatar {
      overflow: hidden;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      margin-right: 8px;

      animation: growfade-in 0.3s 0.1s backwards;
      border-radius: 50%;

      @keyframes growfade-in {
        from {
          height: 0;
          opacity: 0;
        }
      }

      svg {
        display: block;
        width: 32px;
      }
    }
    .messages {
      flex: 1;
    }
  }
}

.message {
  position: relative;

  .bubble {
    overflow: hidden;
    width: fit-content;
    max-width: 90%;
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 18px;
    line-height: 20px;
    transition: border-radius 0.3s;

    .content {
      width: fit-content;
      word-wrap: break-word;
    }

    &.measure {
      position: absolute;
    }
  }

  &.sent .bubble {
    margin-left: auto;
    background: var(--message);
    color: var(--on-message);

    &.last {
      border-bottom-right-radius: 4px;
    }
  }
  &.received .bubble {
    background: var(--outline);

    &.last {
      border-bottom-left-radius: 4px;
    }
  }
}

.loading-indicator {
  display: flex;
  height: 20px;
  align-items: center;
  margin: 0 4px;

  > div {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    margin-right: 6px;

    animation: hop 1.5s infinite;
    background: var(--primary);
    border-radius: 50%;
    @keyframes hop {
      0% {
        transform: none;
      }
      20% {
        transform: translateY(-4px);
      }
      40% {
        transform: none;
      }
    }

    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      margin-right: 0;
      animation-delay: 0.2s;
    }
  }
}

.account-list {
  padding-top: 8px;
  .expand {
    margin-top: 4px;
  }
}

.account-link {
  padding: 8px 12px;
  margin-top: 4px;
  background: white;
  border-radius: 8px;
}

.actions {
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  .container {
    display: flex;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .action {
    width: 160px;
    box-sizing: border-box;
    flex-shrink: 0;
    margin: 1px;
    border-radius: 16px;
    text-align: center;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.prompt {
  overflow: hidden;
  padding: 12px;
  padding-bottom: 12px + 16px;
  margin: -12px;

  .option-list {
    max-width: 90%;
    border: 1px solid var(--outline);
    margin-left: auto;
    border-radius: 18px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 0 12px 0 #00000018;

    > * {
      padding: 8px 16px;
    }
    > :not(:last-child) {
      border-bottom: 1px solid var(--outline);
    }
  }
}
