// Legacy style imports, use css modules
@import "~@component/dashboard/tour.scss";

@import "@kikoff/client-utils/src/stylesheets/all.scss";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: var(--container);

  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

#__next,
#app-root,
#page-root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  &[type="radio"] {
    border: none;
    margin-right: 10px;

    appearance: none;

    &::after {
      display: inline-block;
      width: 1em;
      height: 1em;
      box-sizing: border-box;
      border: 1px solid var(--moderate);

      background: white;
      border-radius: 1em;

      content: "";

      transition: border 0.3s;
    }

    &:hover::after {
      border-width: 2px;
      border-color: var(--primary);
    }

    &:checked {
      &::after {
        border-width: 0.4em;
        border-color: var(--primary);
      }
    }
  }

  &[type="submit"] {
    -webkit-appearance: none;
  }
}

a.default {
  color: var(--primary);
  text-decoration: none;
}

a.button {
  display: inline-block;
  padding: 10px 30px;

  background: var(--primary);
  border-radius: 30px;

  color: white;
  font-weight: 500;
  text-decoration: none;
}

button {
  cursor: pointer;
  font: inherit;

  outline: none;
}
