@import "@kikoff/client-utils/src/stylesheets/constants/stacking-context.scss";

.bottom-sheet-layout {
  position: fixed;
  z-index: z(modal);
  bottom: 0;
  display: flex;
  width: 100%;
  max-height: 100%;
  justify-content: center;
  pointer-events: none;
  transition: bottom 0.4s;

  > * {
    pointer-events: all;
  }
  @media screen and (max-width: 699px) {
    &.current {
      animation: slide-up 0.4s;
    }
    &:not(.current) {
      bottom: -100%;
    }

    @keyframes slide-up {
      from {
        bottom: -100%;
      }
    }
  }

  @media screen and (min-width: 700px) {
    left: 0;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    padding: 16px 0;
    opacity: 0;
    transition: 0.4s;

    &.present {
      animation: fade-down 0.3s 0.1s backwards;
      opacity: 1;
      @keyframes fade-down {
        from {
          opacity: 0;
          transform: translateY(-50px);
        }
        to {
          opacity: 1;
          transform: none;
        }
      }
    }
    &.left {
      left: -50%;
      opacity: 0;
    }
    &.right {
      left: 50%;
      opacity: 0;
    }
  }
}
