.error-wrapper {
  position: relative;

  display: flex;
  overflow: hidden;
  flex: 1;
  justify-content: center;

  .error.panel {
    display: flex;
    overflow: hidden;
    height: 0;
    flex: 0;
    align-items: flex-start;

    border-radius: 3px;
    overflow-wrap: normal;

    white-space: normal;
    .type {
      font-weight: 500;
    }
    .options {
      display: flex;
      width: 25px;
      box-sizing: border-box;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      border-radius: 3px;

      font-size: 13px;
      line-height: 13px;
      .option {
        cursor: pointer;
      }
    }
    .message {
      overflow: hidden;
      box-sizing: border-box;
      padding-left: 10px;

      text-align: left;

      white-space: pre-wrap;
    }
  }
}
