.alert {
  display: flex;
  padding: 16px !important;

  > :first-child {
    margin-right: 16px;
    font-size: 20px;
  }
  .content {
    flex: 1;
    > header {
      margin-bottom: 8px;
      + div:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
