@import "@kikoff/client-utils/src/stylesheets/constants/stacking-context.scss";

$bottom-nav-height: 70px;
$overlay-z-index: 200;

.help-button {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3), 0 0 12px 12px rgba(0, 255, 0, 0.2),
    0 0 24px 16px rgba(0, 255, 0, 0.15), 0 0 36px 24px rgba(0, 255, 0, 0.1);

  &-bubble {
    position: absolute;
    z-index: calc($overlay-z-index + 1);
    top: calc(-100% - 16px);
    right: 0;
    display: flex;
    width: max-content;
    align-items: center;
    padding: 8px 16px;
    background-color: white;
    border-radius: 16px 16px 0 16px;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    gap: 8px;
  }

  &-wrapper {
    position: fixed;
    z-index: calc($overlay-z-index + 1);
    right: 2.5rem;
    bottom: 2.5rem;
  }
}

@media screen and (max-width: $nav-breakpoint) {
  .help-button-bubble {
    display: none;
  }
  .help-button-wrapper {
    z-index: calc($overlay-z-index - 1);
    right: 1rem;
    bottom: calc($bottom-nav-height + 1rem);
  }
}
