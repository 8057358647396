@import "@kikoff/client-utils/src/stylesheets/constants/stacking-context.scss";

.backdrop {
  position: fixed;
  z-index: z(modal);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
  transition: opacity 0.4s;

  &.show {
    animation: fade-in 0.4s;
  }
  &:not(.show) {
    opacity: 0;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
  }
}
