.text\: {
  // ************* LEGACY, DO NOT MODIFY *************
  &eyebrow-1 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07em;
    // 18.41px / 14px
    line-height: 1.315;
    text-transform: uppercase;
  }

  &eyebrow-2 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 15.78px;
    text-transform: uppercase;
  }

  &eyebrow-3 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.08em;
    line-height: 15.78px;
    text-transform: uppercase;
  }

  &legal-primary,
  &caption-primary {
    color: #717171;
    font-size: 12px;
    letter-spacing: 0.005em;
    // 15.91px / 12px
    line-height: 1.326;
  }

  &caption-secondary {
    color: #a5a5a5;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.005em;
    line-height: 13.26px;
  }

  &heading-7 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 23.67px;
  }
}
