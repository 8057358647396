.flex {
  display: flex;
}
.contents {
  display: contents;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}

.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}

.row,
.row-center {
  display: flex;
  flex-direction: row;
}

.column,
.column-center {
  display: flex;
  flex-direction: column;
}

.row-center,
.column-center {
  align-items: center;
}

.expand {
  flex: 1;
}

.center-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-box {
  box-sizing: border-box;
}
.fit-content {
  width: fit-content;
}
.block {
  display: block;
}

.inline {
  display: inline;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}
